<template>
  <LMap ref="myMap" :center="center" :zoom="zoom" @ready="handleReady">
    <slot v-if="isReady" />
    <l-tile-layer :url="url" :attribution="attribution"></l-tile-layer>
  </LMap>
</template>
<script>
import { $autoZoom } from '../helper';
import 'leaflet/dist/leaflet.css';
import { LMap, LTileLayer } from 'vue2-leaflet';
import { dark, light } from './design';
import { Icon } from 'leaflet';

export default {
  components: {
    LTileLayer,
    LMap
  },
  created() {
    if (!this.isGmaps) {
      delete Icon.Default.prototype._getIconUrl;
      Icon.Default.mergeOptions({
        iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
        iconUrl: require('leaflet/dist/images/marker-icon.png'),
        shadowUrl: require('leaflet/dist/images/marker-shadow.png')
      });
    }
  },
  updated() {
    console.log('::OS MAP Update');
  },
  data() {
    return {
      attribution:
        '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      isReady: false,
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
    };
  },
  computed: {
    mapObject() {
      return this.$refs.myMap.mapObject;
    },
    myOptions() {
      // TODO: complete styles
      const styles = this.darkMode ? dark : light;
      return { ...styles, ...this.options };
    }
  },
  methods: {
    autoZoom(bounds, adjustZoom = 0, maxZoom = '18') {
      $autoZoom({ map: this.mapObject, bounds, adjustZoom, maxZoom }, () => {
        this.mapObject.fitBounds(bounds);
      });
    },
    async handleReady(map) {
      this.isReady = true;
      this.$emit('ready', map);
    }
  },

  props: {
    center: { type: Object, default: () => [] },
    darkMode: { type: Boolean, default: false },
    options: { type: Object, default: () => {} },
    zoom: { type: Number, default: 10 }
  }
};
</script>
